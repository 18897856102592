<template>
<v-main >
	<!-- SideBar -->
	<!-- Barre de Navigation -->
	<v-container fluid fill-height>
		<v-row align-center justify-center>
			<v-col xs12 sm8 md4>
				<h1 class="blue-h">Liste des Implants</h1>
				<v-data-table v-if="show == 1"
					:headers="headers"
					:items="tags"
					:items-per-page="5"
					class="elevation-1"
					>
					<!-- ... autres slots ... -->
					<template v-slot:item.action="{ item }">
						<v-btn @click="tag_name = item; dialog = true;">Supprimer</v-btn>
					</template>
					<template v-slot:item.email="{ item }">
						<v-select
						:items="item.email"
						label="Emails"
						chips
						dense
						></v-select>
					</template>
					<template v-slot:item.last_used="{ item }">
						{{ formatDate(item.last_used) }}
					</template>
				</v-data-table>
			</v-col>
		</v-row>
		<v-dialog v-model="dialog" max-width="290">
			<v-card>
				<v-card-title class="headline">Confirmation</v-card-title>
				<v-card-text>
					Êtes-vous sûr de vouloir supprimer le tag {{ tag_name.tag_id }} ? Il sera dépossédé de tous les utilisateurs le possédant
				</v-card-text>
				<v-card-actions>
					<v-btn color="blue darken-1" text @click="dialog = false">Non</v-btn>
					<v-btn color="blue darken-1" text @click="supressTags">Oui</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-container>
</v-main>


</template>
<script>
import {getDataLastId, getDurationCapteurs, getStockage } from '../functions/get-data'
import { switchToken } from '../functions/test-token';
import moment from 'moment';
moment.locale('fr');
	export default {
		data(){
			return {
				show: 0,
				headers: [
					{
						text: 'id',
						align: 'start',
						value: 'id',
					},
					{ text: 'Actions', value: 'action', sortable: false },
					{ text: 'tag_id', value: 'tag_id' },
					{ text: 'durée d\'utilisation', value: 'durée' },
					{ text: 'nombre de data', value: 'stockage' },
					{ text: 'utilisateur', value: 'email' },
					{ text: 'Dernière utilisation', value: 'last_used' }
				],
				tags: [
					{
						id: 1,
						tag_id: '',
						email: [''],
					},
				],
				dialog: false,
				tag_name: '',
			}
		},

    async mounted(){
		await this.GetTags();
		await this.getDuration();
		await this.getStockage();
		
		
	},

		methods: {
			
        async GetTags(){
            const url = this.$api.getRESTApiUri() + `/get-tag-id`;
			const accessToken = localStorage.getItem('accessToken');
			const headers = new Headers({
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${accessToken}`
			});
			return fetch(url, { headers: headers })
				.then(res => {
				if (res.status === 401) {
					switchToken().then(() => {
					// Refaites la requête avec le nouveau token
						this.GetTags();
					});
				}
				return res.text();
			})
            .then((result) => {
				this.tags = JSON.parse(result)
				this.tags.map((element => console.log(element.tag_id)))
				this.getAllData();
            })
			.catch((error) => {
				console.log(error)
			});
		},
		async getDuration() {
			for (let i = 0; i < this.tags.length; i++) {
				const tag_id = this.tags[i].tag_id;
				const total_duration  = await getDurationCapteurs(tag_id)
				this.tags[i].durée = await formatDuration(total_duration)
			}
		},
		formatDuration(seconds) {
			const days = Math.floor(seconds / (24 * 3600));
			seconds -= days * 24 * 3600;
			const hours = Math.floor(seconds / 3600);
			seconds -= hours * 3600;
			const minutes = Math.floor(seconds / 60);
			seconds -= minutes * 60;
			return `${days}J ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${Math.floor(seconds).toString().padStart(2, '0')}`;
		},

			async getStockage() {
				let results = [];
				for (let i = 0; i < this.tags.length; i++) {
					const tag_id = this.tags[i].tag_id;
					const result = await getStockage(tag_id)
					results.push(result);
				}
				for (let i = 0; i < results.length; i++) {
					let duration = results[i] ? results[i][0].table_size : '';	
					// Affectation de la durée formatée à la propriété 'durée' de l'objet correspondant
					this.tags[i].stockage = duration;
				}
				this.show = 1;
			},
			async supressTags(){
				const url = this.$api.getRESTApiUri() + `/admin/supress-tags`;
				const accessToken = localStorage.getItem('accessToken')
				return fetch(url, {
						method: 'PUT',
						headers: {
							'Content-Type': 'application/json',
							'Authorization': `Bearer ${accessToken}`
						},
						body: JSON.stringify({
							tag_id: this.tag_name.tag_id,
							email: this.tag_name.email,
						}),
					})
				.then(res => {
					if (res.status === 401) {
						switchToken().then(() => {
							// Refaites la requête avec le nouveau token
							this.supressTags();
						});
					}
					return res.text();
				})
				.then((result) => {
					// this.tags = this.tags.filter(tag => tag.tag_id !== this.tag_name.tag_id);
					this.dialog =false ;
				})
				.catch((error) => {
					console.error('Error:', error);
					// Handle the error if needed
				});
			},
			
			async getAllData() {
				for (let i = 0; i < this.tags.length; i++) {
					const tag_id = this.tags[i].tag_id;
					const data = await getDataLastId(tag_id)
					this.tags[i].last_used = data[0].received_on;
				}
			},
			formatDate(date) {
				return moment(date).format('LL, LT');
			},
		},
		beforeDestroy() {
			clearInterval(this.interval2);
		},
	}
</script>