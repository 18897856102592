<template>
<v-main class="padding100" >
		<navbar/>
		<side-bar-admin/>
		<listematrice/>
		<listelecteur/>
		<listeimplant/>
		<listeenvironnement/>
		<ListeAmmoniac/>
		<ListeSphere/>
		<ListeVibration/>
</v-main>
	
</template>

<script>
import navbar from '@/components/NavBarCloud.vue';
import listematrice from '../../components/listeMatrice.vue';
import listelecteur from '../../components/listeLecteur.vue';
import listeimplant from '../../components/listeImplant.vue';
import listeenvironnement from '../../components/listeEnvironnement.vue';
import ListeAmmoniac from '../../components/listeAmmoniac.vue';
import ListeSphere from '../../components/listeSphere.vue';
import ListeVibration from '../../components/listeVibration.vue';
import SideBarAdmin from '../../components/SideBarAdmin.vue';

	export default {
		components: {
			navbar,
			listematrice,
			listeimplant,
			listelecteur,
			listeenvironnement,
			ListeAmmoniac,
			ListeSphere,
			ListeVibration,
			SideBarAdmin,
		},
	}
</script>

